import Markdown from "./Markdown";
import {
    Container,
    Card
} from 'react-bootstrap';
import Unity, { UnityContext } from "react-unity-webgl";
import { useEffect, useState } from "react";

export default function GamesPage(props)
{
    const [active, setActive] = useState(true);

    useEffect(() => {
        return () => {
            setActive(false); // Disable Unity when navigating away
            //window.location.reload(false); // Reload page
        }
    });

    const unityContext = new UnityContext({
        loaderUrl: `/builds/${props.game}/${props.game}.loader.js`,
        dataUrl: `/builds/${props.game}/${props.game}.data`,
        frameworkUrl: `/builds/${props.game}/${props.game}.framework.js`,
        codeUrl: `/builds/${props.game}/${props.game}.wasm`,
    });

    return <Container>
        <h1>Game Page</h1>
        <em>Game ID: {props.game}</em>
        <Container>
            {active ? <Unity unityContext={unityContext} style={{width: props.width, height: props.height, display: "block", margin: "auto"}}/> : <Card>Unity Inactive</Card> }
        </Container>
        <Markdown mdSrc={"gamedesc/"+props.game} />
    </Container>;
}

export function LegacyGamesPage(props)
{
    return <Container>
        <h1>Game Page</h1>
        <em>Game ID: {props.game} (Legacy)</em>
        <iframe
            title="Game View"
            src={`/builds/${props.game}/index.html`}
            style={{
                width: "960px",
                height: "640px",
                display: "block",
                margin: "auto"
            }}
            scrolling="no"
        />
        <Markdown mdSrc={"gamedesc/"+props.game} />
    </Container>;
}