import { useState } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Container } from 'react-bootstrap';

export default function Markdown(props) {
    const [contents, setContents] = useState();

    fetch(`/pages/${props.mdSrc}.md`)
        .then(r => r.text())
        .then((data) => setContents(data));

    return <Container style={{marginTop: "2em"}}>
        <ReactMarkdown
            children={contents}
            plugins={[remarkGfm]}
            renderers={{
                sub: 'sub',
                sup: 'sup',
            }}
        />
    </Container>;
}