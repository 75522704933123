import { useParams } from "react-router";
import {
    Container,
} from 'react-bootstrap';
import GoogleLogin from "react-google-login";

export default function AccountPage()
{
    let account = useParams.account ? useParams.account : "no-account";

    const handleLogin = async googleData => {
        const res = await fetch("/api/v1/auth/google", {
            method: "POST",
            body: JSON.stringify({
            token: googleData.tokenId
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });
        const data = await res.json();
        console.log(data);
        // store returned user somehow
    };

    return <Container>
        {account}
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Log in with Google"
            onSuccess={handleLogin}
            onFailure={handleLogin}
            cookiePolicy={'single_host_origin'}
        />
        <h1>NOTICE</h1>
        <p>This page is currently under construction. The above button does not work.</p>
    </Container>;
}