import {
    Container
} from 'react-bootstrap';
import Markdown from "./Markdown";

export default function PressKitPage(props)
{
    return <Container>
        <Markdown mdSrc={"presskits/"+props.kit} />
        {props.images.map(img => <img className="thumbnail-image" src={img.src} alt={img.alt}/>)}
    </Container>
}