import './App.scss';
import {
  Link,
  Outlet,
} from 'react-router-dom';
import {
  Container,
  Navbar, Nav, NavDropdown,
} from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <Navbar expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              alt=""
              src="/icons/icon-512.png"
              width="32"
              height="32"
              className="d-inline-block align-top"
            />{' '}
          SoshJam
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">

              <Nav.Link as={Link} to="/">Home</Nav.Link>

              <NavDropdown title="Games" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/games">All Games</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/games/topspin">Topspin</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/games/jeffreys-tower">Jeffrey's Tower</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/games/topspin-og">Topspin (Original)</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/games/jeffreys-tower-og">Jeffrey's Tower (Original)</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/presskit">Press Kit</Nav.Link>

              {/* Temporary */}
              {/*<Nav.Link as={Link} to="/games/testing">Test Page Shortcut</Nav.Link>*/}

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
      <Outlet />

      <Container>
        <p>&copy; {new Date().getFullYear()} SoshJam Games</p>
      </Container>
    </div>
  );
}

export default App;
