import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Markdown from './components/Markdown';
import GamesPage, { LegacyGamesPage } from './components/GamesPage';
import AccountPage from './components/AccountPage';
import PressKitPage from './components/PressKit';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Markdown mdSrc="home" />} />
          <Route path="games">
            <Route index element={<Markdown mdSrc="games" />} />
            <Route path="testing" element={<GamesPage game="testing"/>} />
            <Route path="jeffreys-tower" element={<GamesPage game="jeffreys-tower" width="960px" height="640px"/>} />
            <Route path="topspin" element={<React.Fragment>
              <img src="/img/topspinlogo.png" style={{marginTop: "2em"}} alt="Topspin Logo"/>
              <div className="video-preview-container"><div><iframe title="Trailer" src="https://www.youtube.com/embed/-Elr91avq3E" className="video-preview"></iframe></div></div>
              <iframe className="steam-widget" src="https://store.steampowered.com/widget/1937960/?t=Jump%20and%20dash%20your%20way%20to%20victory%20in%20this%20vibrant%20side-scrolling%20platformer.%20Navigate%20through%20stunning%20environments%20and%20intricate%20puzzles%20on%20your%20quest%20to%20punch%20powerful%20enemies%20and%20save%20your%20kingdom." frameborder="0" width="646" height="190"></iframe>
              <Markdown mdSrc="gamedesc/topspin"/>
            </React.Fragment>} />
            <Route path="topspin-secret-demo" element={<GamesPage game="topspindemo" width="1138px" height="640px"/>} />
            <Route path="jeffreys-tower-og" element={<LegacyGamesPage game="jeffreys-tower-og"/>} />
            <Route path="topspin-og" element={<LegacyGamesPage game="topspin-og"/>} />
          </Route>
          <Route path="presskit">
            <Route index element={<Markdown mdSrc="presskit"/>} />
            <Route path="topspin" element={<PressKitPage kit="topspin" images={[
              {src: "/img/soshjamlogo.png", alt:"SoshJam Logo"},
              {src: "/img/topspinlogo.png", alt:"Topspin Logo"},
              {src: "/img/promotionalimage.png", alt:"Topspin Promotional Image"},
              {src: "/img/boxart.png", alt:"Topspin Box Art"},
              {src: "/img/screenshot1.png", alt:"Screenshot"},
              {src: "/img/screenshot2.png", alt:"Screenshot"},
              {src: "/img/screenshot3.png", alt:"Screenshot"},
              {src: "/img/screenshot4.png", alt:"Screenshot"},
              {src: "/img/screenshot5.png", alt:"Screenshot"},
              {src: "/img/gifs/gif1.gif", alt:"Gif"},
              {src: "/img/gifs/gif2.gif", alt:"Gif"},
              {src: "/img/gifs/gif3.gif", alt:"Gif"},
              {src: "/img/gifs/gif4.gif", alt:"Gif"},
              {src: "/img/gifs/gif5.gif", alt:"Gif"},
            ]} />} />
          </Route>
          <Route path="account">
            <Route index element={<AccountPage />} />
            <Route path=":account" element={<AccountPage />} />
          </Route>
          <Route path="topspin" element={<Navigate to="/games/topspin"/>} />
          <Route path="*" element={<Markdown mdSrc="404" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
